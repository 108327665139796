import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import Configure from "./Configure";
import Chapters from "./Chapters";

// Styled component for the tab container
const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(27, 123, 225, 0.08);
  border-radius: 20px;
  margin: 0px 25px;
`;

// Styled component for individual tabs
const Tab = styled.button`
  background-color: ${({ selected }) => (selected ? "#007BFF" : "transparent")};
  color: ${({ selected }) => (selected ? "#fff" : "#333")};
  border: none;
  border-radius: 20px;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ selected }) => (selected ? "#007BFF" : "#e0e0e0")};
  }
  width: 100%;
`;

const ContentContainer = styled.div`
  text-align: center;
  padding: 5px;
  border: 1px solid red;
  height: 100%;
`;

// Content for different tabs

const Description = ({ matchedChapter }) => (
  <Box
    style={{
      maxHeight: "140px",
      overflowY: "hidden",
      textAlign: "center",
      paddingTop: "10px",
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: "600",
        fontSize: "20px",
        fontFamily: "Urbanist",
        marginBottom: "8px",
      }}
    >
      {matchedChapter?.display_title}
    </Typography>

    <Box
      style={{
        maxHeight: "100px",
        overflowY: "auto",
      }}
    >
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "14px",
          fontFamily: "Urbanist",
          lineHeight: "21px",
        }}
        variant="body2"
      >
        {matchedChapter?.display_text}
      </Typography>
    </Box>
  </Box>
);

const TabbedContent = ({ matchedChapter, getData, currVariant }) => {
  const [selectedTab, setSelectedTab] = useState("description");

  return (
    <Box style={{}}>
      {/* Tabs */}

      <TabContainer>
        <Tab
          selected={selectedTab === "description"}
          onClick={() => setSelectedTab("description")}
        >
          Description
        </Tab>
        <Tab
          selected={selectedTab === "configure"}
          onClick={() => setSelectedTab("configure")}
        >
          Configure
        </Tab>
        <Tab
          selected={selectedTab === "chapters"}
          onClick={() => setSelectedTab("chapters")}
        >
          Chapters
        </Tab>
      </TabContainer>

      {/* Content Based on Selected Tab */}
      {selectedTab === "description" && (
        <Description matchedChapter={matchedChapter} />
      )}
      {selectedTab === "configure" && (
        <Configure getData={getData} currVariant={currVariant} />
      )}
      {selectedTab === "chapters" && <Chapters getData={getData} />}
    </Box>
  );
};

export default TabbedContent;

import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  margin-bottom: 16px;
  box-shadow: none;

  &.MuiAccordion-root:before {
    display: none;
  }
`;

const ActComponent = ({ actList, interactionId }) => {
  console.log("interactionId", interactionId);
  // const actList = [
  //   {
  //     act_id: "01",
  //     is_launch_act: true,
  //     display_sequence: 1,
  //     act_title: "This is Act 01 title",
  //     act_text: "This is Act 01 text",
  //     interactions: [
  //       {
  //         interaction_id: "01",
  //         interaction_title: "this is interaction 01 of act 01",
  //         interaction_text: "this is interaction 01 text of act 01",
  //       },
  //       {
  //         interaction_id: "02",
  //         interaction_title: "this is interaction 02 of act 01",
  //         interaction_text: "this is interaction 02 text of act 01",
  //       },
  //       {
  //         interaction_id: "03",
  //         interaction_title: "this is interaction 03 of act 01",
  //         interaction_text: "this is interaction 03 text of act 01",
  //       },
  //     ],
  //   },
  // ];

  const [expandedAct, setExpandedAct] = useState(false);
  const [expandedInteractions, setExpandedInteractions] = useState({});

  useEffect(() => {
    if (interactionId) {
      const targetActIndex = actList.findIndex((act) =>
        act.interactions.some(
          (interaction) => interaction.interaction_id === interactionId
        )
      );
      if (targetActIndex !== -1) {
        setExpandedAct(`panel${targetActIndex}`);
        setExpandedInteractions({
          [targetActIndex]: `interaction${interactionId}`,
        });
      }
    }
  }, [interactionId]);

  const handleActChange = (panel) => (event, isExpanded) => {
    setExpandedAct(isExpanded ? panel : false);
  };

  const handleInteractionChange =
    (actIndex, interactionPanel) => (event, isExpanded) => {
      setExpandedInteractions((prevState) => ({
        ...prevState,
        [actIndex]: isExpanded ? interactionPanel : false,
      }));
    };

  return (
    <Box>
      {actList?.map((act, actIndex) => (
        <StyledAccordion
          key={act.act_id}
          expanded={expandedAct === `panel${actIndex}`}
          onChange={handleActChange(`panel${actIndex}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${actIndex}bh-content`}
            id={`panel${actIndex}bh-header`}
          >
            <Box>
              <Typography
                sx={{ fontWeight: "500", fontSize: "20px", color: "#4B5563" }}
              >
                {act.act_title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#6B7280",
                  mt: 0.5,
                }}
              >
                {act.act_text}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {act.interactions.map((interaction) => (
              <StyledAccordion
                key={interaction.interaction_id}
                expanded={
                  expandedInteractions[actIndex] ===
                  `interaction${interaction.interaction_id}`
                }
                onChange={handleInteractionChange(
                  actIndex,
                  `interaction${interaction.interaction_id}`
                )}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`interaction${interaction.interaction_id}-content`}
                  id={`interaction${interaction.interaction_id}-header`}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#374151",
                    }}
                  >
                    {interaction.interaction_title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#4B5563",
                    }}
                  >
                    {interaction.interaction_text}
                  </Typography>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default ActComponent;

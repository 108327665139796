import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";

const StyledTabPanel = styled.div`
  padding: 16px;
  background-color: #f5f5f5;
  min-height: 150px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const TabsComponent = ({ actList, interactionId }) => {
  // const actList = [
  //   {
  //     act_id: "01",
  //     is_launch_act: true,
  //     display_sequence: 1,
  //     act_title: "This is Act 01 title",
  //     act_text: "This is Act 01 text",
  //     interactions: [
  //       {
  //         interaction_id: "01",
  //         interaction_title: "this is interaction 01 of act 01",
  //         interaction_text: "this is interaction 01 text of act 01",
  //       },
  //       {
  //         interaction_id: "02",
  //         interaction_title: "this is interaction 02 of act 01",
  //         interaction_text: "this is interaction 02 text of act 01",
  //       },
  //       {
  //         interaction_id: "03",
  //         interaction_title: "this is interaction 03 of act 01",
  //         interaction_text: "this is interaction 03 text of act 01",
  //       },
  //     ],
  //   },
  // ];

  const [value, setValue] = useState(0);
  const [interactionIndex, setInteractionIndex] = useState(0);

  // Sort actList: is_launch_act first, then by display_sequence
  const sortedActList = actList?.sort(
    (a, b) =>
      b.is_launch_act - a.is_launch_act ||
      a.display_sequence - b.display_sequence
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setInteractionIndex(0); // Reset interaction index on act change
  };

  const handlePrevInteraction = () => {
    if (interactionIndex > 0) {
      setInteractionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextInteraction = () => {
    const currentAct = sortedActList?.[value];
    if (currentAct && interactionIndex < currentAct.interactions.length - 1) {
      setInteractionIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Get current act and interaction based on value and interactionIndex
  const currentAct = sortedActList?.[value];
  const currentInteraction = currentAct?.interactions?.[interactionIndex];

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs Header */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs with navigation"
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { backgroundColor: "#1976d2" } }}
      >
        {sortedActList?.map((act, index) => (
          <Tab key={index} label={act.act_title} />
        ))}
      </Tabs>

      {/* Tab Content */}
      {sortedActList?.map((act, index) => (
        <div
          key={index}
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {value === index && (
            <StyledTabPanel>
              <NavContainer>
                <Typography variant="h6">
                  {interactionId && currentInteraction
                    ? `Interaction ${interactionIndex + 1} of ${
                        currentAct.interactions.length
                      }`
                    : act.act_title}
                </Typography>

                {interactionId && currentAct?.interactions.length > 1 && (
                  <div>
                    <IconButton
                      onClick={handlePrevInteraction}
                      disabled={interactionIndex === 0}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleNextInteraction}
                      disabled={
                        interactionIndex === currentAct.interactions.length - 1
                      }
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </div>
                )}
              </NavContainer>

              {!interactionId || !currentInteraction ? (
                <Typography>{act.act_text}</Typography>
              ) : (
                <>
                  <Box>
                    <Typography variant="h6">
                      {currentInteraction?.interaction_title}
                    </Typography>
                  </Box>
                  <Typography>
                    {currentInteraction?.interaction_text}
                  </Typography>
                </>
              )}
            </StyledTabPanel>
          )}
        </div>
      ))}
    </Box>
  );
};

export default TabsComponent;
